import {HTTP} from "@/http";
import {AxiosResponse} from "axios";
import {sleep} from "@/functions/utils";

export default {
    getSidenavInfo() {
        return HTTP.get(`/user/side-nav-info`)
            .then(async (response: AxiosResponse) => {
                await sleep(1000);
                return response;
            }).catch((response) => {
                return response;
            });
    },
};
