import {defineStore} from "pinia";
import User from "@/types/User";
import usersApi from '@/api/usersApi.ts';
import {AxiosResponse} from "axios";
import {LocationQueryValue} from "vue-router";
import {clearAuthorization, HTTP, setAuthorization} from "@/http";

export const storeUser = defineStore({
    id: "storeUser",
    state: () => ({
        user: {
            company: '',
            email: '',
            firstname: '',
            id: '',
            lastname: '',
            role: '',
            groupId: '',
            groupName: '',
            preferences: undefined,
        } as User,
        isLogged: false as boolean,
    }),

    actions: {
        login(email: string, password: string) {
            return usersApi.login(email, password).then((response: AxiosResponse) => {
                return response;
            });
        },
        getInfos() {
            return usersApi.getInfos().then((response: AxiosResponse) => {
                return response;
            });
        },
        fillInfos(user: any, preferences: string, token: string | null | LocationQueryValue[]) {
            localStorage.setItem('ef-auth', JSON.stringify({ token: token }));
            setAuthorization(token);
            this.isLogged = true;
            this.user = {
                company: user.companyName,
                role: user.role,
                email: user.email,
                firstname: user.firstname,
                id: user.id,
                lastname: user.lastname,
                groupId: user.groupId,
                groupName: user.groupName,
                preferences: this.loadPreferences(preferences),
            };
        },
        loadPreferences(preferences: string) {
            return JSON.parse(preferences);
        },
        logout() {
            this.isLogged = false;
            clearAuthorization();
            localStorage.removeItem('ef-auth');
        },
        getAllUsers(start: number, end: number) {
            return usersApi.getAllUsers(start, end).then((response: AxiosResponse) => {
                return response.data;
            });
        },
        getUserById(userId: string) {
            return usersApi.getUserById(userId).then((response: AxiosResponse) => {
                return response.data;
            });
        },
        editUser(userId: string, firstname: string, lastname: string, email: string) {
            return usersApi.editUser(userId, firstname, lastname, email).then((response: AxiosResponse) => {
                return response.data;
            });
        },
        createCollab(firstname: string, lastname: string, email: string) {
            return usersApi.createCollab(firstname, lastname, email).then((response: AxiosResponse) => {
                return response;
            });
        },
        removeCollab(userId: string) {
            return usersApi.removeCollab(userId).then((response: AxiosResponse) => {
                return response;
            });
        },
    },
});
