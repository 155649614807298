export function sleep(ms: number = 0): Promise<void> {
    console.log('Kindly remember to remove `sleep`');
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export function arrayToggleBaseOnId(array: Array<any>, value: any) {
    const index = array.findIndex((el) => el.id === value.id);
    if (index === -1) {
        array.push(value);
    } else {
        array.splice(index, 1);
    }
}

export function arrayHasBasedOnId(array: Array<any>, value: any) {
    return array.findIndex((el) => el.id === value.id) !== -1;
}

export function arrayToggle(array: Array<any>, value: any) {
    let index = array.indexOf(value);
    if (index === -1) {
        array.push(value);
    } else {
        array.splice(index, 1);
    }
}

export function arrayHas(array: Array<any>, value: any) {
    return array.indexOf(value) !== -1;
}

export function isActionAvailable(array: Array<any>, action: string) {
    let isAvailable = true;
    for (const item of array) {
        isAvailable = item.actions.find((subItem: any) => subItem.action === action);
        if (!isAvailable) break;
    }
    return isAvailable;
}

export function randomColor(id: string) {
    let str = id.replace(/\D/g, '');
    switch(Math.trunc(Number(str)/1000000 % 16)) {
        case 0:
            return '#000';
        case 1:
            return '#34568B';
        case 2:
            return '#FF6F61';
        case 3:
            return '#6B5B95';
        case 4:
            return '#88B04B';
        case 5:
            return '#F7CAC9';
        case 6:
            return '#92A8D1';
        case 7:
            return '#955251';
        case 8:
            return '#B565A7';
        case 9:
            return '#009B77';
        case 10:
            return '#DD4124';
        case 11:
            return '#D65076';
        case 12:
            return '#9B2335';
        case 13:
            return '#E15D44';
        case 14:
            return '#98B4D4';
        case 15:
            return '#7FCDCD';
        case 16:
            return '#DFCFBE';
    }
}

export const getDuration = (duration: number) => {
    let hours = Math.floor((duration / 60));
    let minutes = Math.round(((duration / 60) - Math.floor((duration / 60))) * 60);
    return (hours > 0 ? hours + (hours > 1 ? " heures" : " heure") : "") + (hours > 0 && minutes > 0 ? " et " : "") + (minutes > 0 ? minutes + (minutes > 1 ? " minutes" : " minute") : "");
}

