import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import { storeUser } from "@/stores/user";
import {AxiosResponse} from "axios";
import {setAuthorization} from "@/http";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: { name: 'Home' }
  },
  {
    path: "/login",
    name: "Login",
    component: () => import('@/views/Login.vue'),
  },
  {
    path: "/home",
    name: "Home",
    component: () => import('@/views/Home.vue'),
  },
  {
    path: "/trainings",
    name: "Trainings",
    component: () => import('@/views/Trainings.vue'),
  },
  {
    path: "/trainings/:trainingId",
    name: "Training",
    component: () => import('@/views/Training.vue'),
  },
  {
    path: "/trainingpaths",
    name: "TrainingPaths",
    component: () => import('@/views/TrainingPaths.vue'),
  },
  {
    path: "/trainingpaths/:trainingPathId",
    name: "TrainingPath",
    component: () => import('@/views/TrainingPath.vue'),
  },
  {
    path: "/users",
    name: "Users",
    component: () => import('@/views/Users.vue'),
  },
  {
    path: "/users/:userId",
    name: "User",
    component: () => import('@/views/User.vue'),
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import('@/views/Profile.vue'),
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: () => import('@/views/Notifications.vue'),
  },
  {
    path: "/infos",
    name: "Infos",
    component: () => import('@/views/Infos.vue'),
  },
  {
    path: "/subscriptions",
    name: "Subscriptions",
    component: () => import('@/views/Subscriptions.vue'),
  },
  {
    path: "/subscriptions/:subscriptionId",
    name: "Subscription",
    component: () => import('@/views/Subscription.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async (to, from, next) => {
  const _storeUser = storeUser();
  const auth = localStorage.getItem('ef-auth');
  if (to.name === 'Login') {
    if (!_storeUser.isLogged) next();
    else {
      _storeUser.logout();
      next();
    }
  } else {
    if (auth === null) next('login');
    else if (from.name !== 'Login' && !_storeUser.isLogged) {
      setAuthorization(JSON.parse(auth).token);
      await _storeUser.getInfos().then(async (response: AxiosResponse) => {
        if (response.status !== 200) {
          next('login');
        } else {
          _storeUser.fillInfos(response.data, response.data.preferences, JSON.parse(auth).token);
          next();
        }
      });
    } else next();
  }
});

export default router;
