import { defineStore } from "pinia";
import InfoTag from "@/types/InfoTag";
import {arrayToggleBaseOnId} from "@/functions/utils";
import {AxiosResponse} from "axios";
import systemApi from '@/api/systemApi.ts';

export const storeSystem = defineStore({
    id: "storeSystem",
    state: () => ({
        isModaleOpen: false,
        infoTags: [] as Array<InfoTag>,
        isDownScroll: false,
        scroll: false,
        notifsCount: 0,
        usersCount: 0,
        trainingsCount: 0,
        trainingPathsCount: 0,
        subscriptionsCount: 0,
        sideNavLoader: false,
    }),

    actions: {
        openModale() {
            this.isModaleOpen = true;
        },
        closeModale() {
            this.isModaleOpen = false;
        },
        addInfoTag(tag: InfoTag) {
            this.infoTags.push(tag);
            tag.id = this.infoTags.length.toString();
            setTimeout(() => {
                this.deleteInfoTag(tag)
            }, 3000)
        },
        deleteInfoTag(tag: InfoTag) {
            arrayToggleBaseOnId(this.infoTags, tag);
        },
        emitScroll(isDownScroll: boolean) {
            this.isDownScroll = isDownScroll;
            this.scroll = !this.scroll;
        },
        getSidenavInfos() {
            this.sideNavLoader = true;
            systemApi.getSidenavInfo().then((response: AxiosResponse) => {
                this.usersCount = response.data.nbCollab;
                this.trainingsCount = response.data.nbTraining;
                this.trainingPathsCount = response.data.nbTrainingPath;
                this.notifsCount = response.data.nbNotif;
                this.subscriptionsCount = response.data.nbSubscriptions;
                this.sideNavLoader = false;
            });
        }
    },
});
