import bank from '@/api/bank.json';
import {clearAuthorization, HTTP, setAuthorization} from '@/http';
import {AxiosResponse} from "axios";
import {sleep} from "@/functions/utils";

export default {
    login(email: string, password: string) {
        return HTTP.post(`/authentication`, {email: email, password: password})
            .then(async (response: AxiosResponse) => {
                return response;
            }).catch((response) => {
                return response;
            });
    },
    getInfos() {
        const auth = localStorage.getItem('ef-auth');
        if (auth != null) {
            setAuthorization(JSON.parse(auth).token);
        }
        return HTTP.get(`/user`, )
            .then(async (response: AxiosResponse) => {
                return response;
            }).catch((response) => {
                clearAuthorization();
                return response;
            });
    },
    getAllUsers(start: number, end: number) {
        return HTTP.get(`/users`, )
            .then(async (response: AxiosResponse) => {
                //await sleep(1000);
                return response;
            }).catch((response) => {
                return response;
            });
    },
    getUserById(userId: string) {
        return HTTP.get(`/user/${userId}`)
            .then(async (response: AxiosResponse) => {
                return response;
            }).catch((response) => {
                return response;
            });
    },
    editUser(userId: string, firstname: string, lastname: string, email: string) {
        return HTTP.put(`/user/${userId}`, {firstname: firstname, lastname: lastname, email: email})
            .then(async (response: AxiosResponse) => {
                return response;
            }).catch((response) => {
                return response;
            });
    },
    createCollab(firstname: string, lastname: string, email: string) {
        return HTTP.post(`/user`, {firstname: firstname, lastname: lastname, email: email})
            .then(async (response: AxiosResponse) => {
                return response;
            }).catch((response) => {
                return response;
            });
    },
    removeCollab(userId: string) {
        return HTTP.delete(`/user/${userId}`)
            .then(async (response: AxiosResponse) => {
                return response;
            }).catch((response) => {
                return response;
            });
    },
};
