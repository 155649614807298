<template>
  <div class="ef-layoutOUT">
    <router-view/>
  </div>
</template>

<script setup lang="ts">
</script>

<style scoped>
.ef-layoutOUT {
  width: 100%;
}
</style>
